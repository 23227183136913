<template>
  <div>
      <component :is="rightComponent"></component>
  </div>
</template>

<script>
export default {
  components: {
    maintenanceStatistics: () => import('../common/maintenanceStatistics')
  },
  data () {
    return {
      rightComponent: 'maintenanceStatistics'
    }
  }
}
</script>
